import React from 'react';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import FloqImageUploader from '../../../components/FloqImageUploader';
import FloqTextInput from '../../../components/FloqTextInput';

const BasicInstitutionForm = ({ formik }) => {
    return (
        <>
            <Stack direction="row" spacing={5} sx={{ width: '100%' }}>
                <Box
                    sx={{
                        width: '150px',
                        height: '150px',
                        minWidth: '150px',
                        minHeight: '150px',
                    }}
                >
                    <FloqImageUploader
                        label="Side panel background"
                        maxMB={2}
                        initialFrameHeight={150}
                        tag="institution-proposal"
                        imageUrl={formik.values.imageUrl}
                        outputDimensions={{ x: 600, y: 600 }}
                        onChange={images => {
                            if (images.length && images[0].imageUrl) {
                                formik.setFieldValue('imageUrl', images[0].imageUrl);
                            } else {
                                formik.setFieldValue('imageUrl', '');
                            }
                        }}
                    ></FloqImageUploader>
                </Box>
                <Box width="100%">
                    <Stack spacing={1} sx={{ width: '100%' }}>
                        <FloqTextInput
                            label="Full name"
                            value={formik.values.name || ''}
                            error={!!formik.errors.name}
                            helperText={formik.errors.name}
                            onChange={value => {
                                formik.setFieldValue('name', value);
                                formik.setErrors({ ...formik.errors, name: '' });
                            }}
                        />
                        <FloqTextInput
                            label="Subtitle (optional)"
                            value={formik.values.subNameList || ''}
                            onChange={value => {
                                formik.setFieldValue('subNameList', value);
                            }}
                        />
                        <Stack direction="row" spacing={1}>
                            <FloqTextInput
                                label="Website URL"
                                value={formik.values.websiteUrl || ''}
                                error={!!formik.errors.websiteUrl}
                                helperText={formik.errors.websiteUrl}
                                variant="filled"
                                onChange={value => {
                                    formik.setFieldValue('websiteUrl', value);
                                    formik.setErrors({
                                        ...formik.errors,
                                        websiteUrl: '',
                                    });
                                }}
                            />
                            <FloqTextInput
                                label="Website name (optional)"
                                value={formik.values.websiteName || ''}
                                error={!!formik.errors.websiteName}
                                helperText={formik.errors.websiteName}
                                disabled={!formik.values.websiteUrl}
                                onChange={value => {
                                    formik.setFieldValue('websiteName', value);
                                    formik.setErrors({
                                        ...formik.errors,
                                        websiteName: '',
                                    });
                                }}
                            />
                        </Stack>
                    </Stack>
                </Box>
            </Stack>
        </>
    );
};

export default BasicInstitutionForm;
