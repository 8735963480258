import React from 'react';
import { useFormikContext } from 'formik';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import FloqTextInput from '../../../components/FloqTextInput';

const InstitutionContentContactTab = ({ institution }) => {
    const formik = useFormikContext();
    const isBooth = institution.typeEntity?.detailPageLayout === 'booth';
    const hasBusinessCardFeature = institution?.hasBusinessCardFeature;

    return (
        <>
            <Box padding={3}>
                <Typography variant="h4">Contact info</Typography>
                <Typography variant="body2" mb={'16px'}>
                    All fields are optional.
                </Typography>
                <Stack spacing={1} sx={{ width: '50%' }}>
                    <FloqTextInput
                        label="Phone"
                        value={formik.values.contactPhoneNumber || ''}
                        error={formik.errors.contactPhoneNumber}
                        onChange={value => {
                            formik.setFieldValue('contactPhoneNumber', value);
                            formik.setErrors({
                                ...formik.errors,
                                contactPhoneNumber: '',
                            });
                        }}
                    />
                    <FloqTextInput
                        label="Email address"
                        value={formik.values.contactEmail || ''}
                        error={formik.errors.contactEmail}
                        onChange={value => {
                            formik.setFieldValue('contactEmail', value);
                            formik.setErrors({
                                ...formik.errors,
                                contactEmail: '',
                            });
                        }}
                    />
                    <FloqTextInput
                        label="Facebook URL"
                        value={formik.values.contactFacebookUrl || ''}
                        error={formik.errors.contactFacebookUrl}
                        onChange={value => {
                            formik.setFieldValue('contactFacebookUrl', value);
                            formik.setErrors({
                                ...formik.errors,
                                contactFacebookUrl: '',
                            });
                        }}
                    />
                    <FloqTextInput
                        label="X URL"
                        value={formik.values.contactTwitterUrl || ''}
                        error={formik.errors.contactTwitterUrl}
                        onChange={value => {
                            formik.setFieldValue('contactTwitterUrl', value);
                            formik.setErrors({
                                ...formik.errors,
                                contactTwitterUrl: '',
                            });
                        }}
                    />
                    <FloqTextInput
                        label="Linkedin URL"
                        value={formik.values.contactLinkedinUrl || ''}
                        error={formik.errors.contactLinkedinUrl}
                        onChange={value => {
                            formik.setFieldValue('contactLinkedinUrl', value);
                            formik.setErrors({
                                ...formik.errors,
                                contactLinkedinUrl: '',
                            });
                        }}
                    />
                </Stack>
            </Box>
            {isBooth && hasBusinessCardFeature && (
                <>
                    <Divider />
                    <Box padding={3}>
                        <Box paddingTop={1} paddingBottom={'16px'} paddingLeft={'2px'}>
                            <Stack
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center"
                                marginBottom={1}
                            >
                                <Typography variant="h4">Drop business card</Typography>
                            </Stack>
                            <Typography variant="body2">
                                Enter the email address the cards and questions should be sent to.
                            </Typography>
                        </Box>
                        <Box width={'50%'}>
                            <FloqTextInput
                                label="Email address"
                                error={formik.errors.businessCardEmail}
                                value={formik.values.businessCardEmail || ''}
                                onChange={value => {
                                    formik.setFieldValue('businessCardEmail', value);
                                    formik.setErrors({
                                        ...formik.errors,
                                        businessCardEmail: '',
                                    });
                                }}
                            />
                        </Box>
                    </Box>
                </>
            )}
        </>
    );
};

export default InstitutionContentContactTab;
