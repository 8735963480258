import React, { useEffect } from 'react';
import Stack from '@mui/material/Stack';
import { useInstitutionProposalsStore } from '../../../stores/InstitutionProposalStore';
import InstitutionListItem from '../../../components/ObjectListItem/Institution';
import NavigationLink from '../../../components/Navigation/NavigationLink';
import { useEventStore } from '../../../stores/EventStore';
import Tooltip from '@mui/material/Tooltip';

const InstitutionsList = props => {
    const eventId = useEventStore(state => state.id);
    const setTitle = useEventStore(state => state.setTitle);
    const fetchProposals = useInstitutionProposalsStore(state => state.fetchProposals);
    const institutionsFromProposals = useInstitutionProposalsStore(state => state.institutions);

    useEffect(() => {
        fetchProposals(eventId);
        setTitle('Exhibitor profiles');
    }, [eventId]);

    return (
        <Stack paddingY={2}>
            {institutionsFromProposals.map(institution => (
                <Tooltip
                    title="No rights to edit this exhibitor's data"
                    disableHoverListener={institution?.allowEditByRepresentative}
                    followCursor={true}
                >
                    <Stack>
                        <NavigationLink
                            path={
                                institution.allowEditByRepresentative
                                    ? `/exhibitordashboard/${institution.id}`
                                    : null
                            }
                        >
                            <InstitutionListItem
                                institution={institution}
                                showBookmark={false}
                                disabled={!institution.allowEditByRepresentative}
                            />
                        </NavigationLink>
                    </Stack>
                </Tooltip>
            ))}
        </Stack>
    );
};

export default InstitutionsList;
