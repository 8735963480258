import { useMemo, useCallback, useEffect } from 'react';
import { create } from 'zustand';
import { useInstitutionById } from './InstitutionsStore';
import { useInstitutionsStore } from './InstitutionsStore';
import { executeQuery } from '../services/api/graphQlRepository';
import sortBy from 'lodash/sortBy';
import { getItemAsync } from '../services/api/data';

export const usePlacesStore = create((set, get) => ({
    placesByFloorplan: {},
    loading: false,
    fetchPlacesByFloorplan: async floorplanId => {
        set({ loading: true });
        const places = await executeQuery('getPlacesWithFloorplan', {
            floorplan: floorplanId,
        });
        const placesWithType = await Promise.all(
            places.map(async place => ({
                ...place,
                typeEntity: await getItemAsync('types', place.type),
            })),
        );
        set(prev => ({
            placesByFloorplan: {
                ...prev.placesByFloorplan,
                [floorplanId]: sortBy(placesWithType, o => o.name),
            },
            loading: false,
        }));
        return placesWithType;
    },
    getPlaceById: async (floorplanId, placeId) => {
        const places = get().placesByFloorplan[floorplanId];
        return places.find(place => place.id === placeId);
    },
}));

export const useGetPlacesByFloorplan = () => {
    const fetchPlacesByFloorplan = usePlacesStore(state => state.fetchPlacesByFloorplan);
    const fetchInstitution = useInstitutionsStore(state => state.fetchInstitution);

    return useCallback(
        async floorplanId => {
            if (!floorplanId) {
                return;
            }

            const places = await fetchPlacesByFloorplan(floorplanId);
            const institutionPromises = [];
            places.forEach(place => {
                if (place.exhibitorId) {
                    institutionPromises.push(fetchInstitution(place.exhibitorId));
                }
            });
            await Promise.allSettled(institutionPromises);
            return places;
        },
        [fetchPlacesByFloorplan, fetchInstitution],
    );
};

export const usePlacesByFloorplan = floorplanId => {
    const placesByFloorplan = usePlacesStore(state => state.placesByFloorplan);
    const getPlacesByFloorplan = useGetPlacesByFloorplan();

    useEffect(() => {
        if (floorplanId) {
            if (!placesByFloorplan[floorplanId]) {
                getPlacesByFloorplan(floorplanId);
            }
        }
    }, [getPlacesByFloorplan, floorplanId, placesByFloorplan]);

    return placesByFloorplan[floorplanId];
};

export const usePlaceByFloorplanAndIdWithInstitution = (floorplanId, placeId) => {
    const place = usePlacesStore(state =>
        (state.placesByFloorplan[floorplanId] || [])?.find(p => p.id === placeId),
    );
    const institution = useInstitutionById(place?.exhibitorId);

    return useMemo(() => (place ? { ...place, institution } : null), [place, institution]);
};
