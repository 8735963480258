import isEmpty from 'lodash/isEmpty';
import hash from 'object-hash';
import * as queries from '../../graphql/queries';
import { batchDecode, objectDecode } from '../graphqlHelper';
import { getCacheItem, setCacheItem } from './graphQlCache';
import { getLocalAppStateAsync } from './db';
import { API } from '../../index';

const encodedFields = [
    'affiliations',
    'classifications',
    'classifiers',
    'invisibleSections',
    'tabs',
    'invites',
    'links',
    'locations',
    'lockedFields',
    'navigation',
    'params',
    'related',
    'relatedOf',
    'resources',
    'roles',
    'rolesOf',
    'sections',
    'tags',
    'value',
    'webpages',
    'banner',
    'homeBanner',
    'slideshow',
    'navItems',
    'timeframes',
    'filter',
    'accessRestrictionGroups',
    'widgets',
    'webapp',
    'kiosk',
    'webappfilters',
];

const generateQueryName = category => {
    let queryName = `get${category.replace(/^./, category[0].toUpperCase())}`;

    if (queries[queryName]) {
        return queryName;
    }

    return queryName + 's';
};

export const getItem = async (category, key, cb, forceQuery = false) => {
    if (!category || !key) {
        return cb(`Invalid query for category: ${category} and key: ${key}`);
    }

    let queryName = generateQueryName(category);
    // Check the cache for the items
    const cacheKey = `${queryName}_${key}`;
    const cachedItem = await getCacheItem(cacheKey, forceQuery);

    if (cachedItem !== null) {
        return cb(null, cachedItem);
    }

    let query = queries[queryName];
    if (!query) {
        return cb(`Function ${queryName} not found`, null);
    }

    let hasError = false;

    const response = await API.graphql({ query, variables: { id: key } }).catch(err => {
        hasError = true;
        console.log(err, query, key);
    });

    if (response && response.data) {
        const result = response.data[queryName] || response.data[queryName + 's'];
        if (result && result.id) {
            const decoded = objectDecode(result, encodedFields);

            setCacheItem(cacheKey, decoded);

            cb(null, decoded);
        } else {
            if (!hasError) {
                setCacheItem(cacheKey, '');
            }

            // return null for empty object
            cb(null, null);
        }
    } else {
        if (!hasError) {
            setCacheItem(cacheKey, '');
        }

        cb(null, null);
    }
};

export const getItemAsync = (category, key, forceQuery) => {
    return new Promise((resolve, reject) => {
        getItem(
            category,
            key,
            (err, res) => {
                if (err) {
                    reject(err);
                } else {
                    resolve(res);
                }
            },
            forceQuery,
        );
    });
};

// Get batch from any table of "Timeslots", "Programelements", "Persons", "Institutions", "Classifiers", "Images", "Places", "Links", "Types";
// data:[{target:"classifiers",ids:["5b5b268ecb62a3432348058b","5b5b268ecb62a3432548058b","5bbf123a882f9c734cd8c60b"]},{target:"links",ids:["5b5b268ecb62a3432548058b","5bbf123a882f9c734cd8c60b"]}]

export const batchGet = async (input, forceQuery = false) => {
    if (isEmpty(input) || isEmpty(input.data)) {
        return {};
    }

    // Check the cache for the items
    const cacheKey = hash(input);
    const cachedItem = await getCacheItem(cacheKey, forceQuery);
    if (cachedItem) {
        return cachedItem;
    }

    try {
        const {
            data: { batchGet: result },
        } = await API.graphql({ query: queries.batchGet, variables: { data: input.data } });
        const decoded = batchDecode(input, result, encodedFields);
        // Save this to cache
        setCacheItem(cacheKey, decoded, 2);
        return decoded;
    } catch (e) {
        setCacheItem(cacheKey, '');

        return {};
    }
};

export const executeQuery = async (queryName, params, forceQuery = false, priority = 5) => {
    if (isEmpty(queryName) || isEmpty(params)) {
        return null;
    }

    const cacheKey = `${queryName}_${JSON.stringify(params)}`;
    const cachedItem = await getCacheItem(cacheKey, forceQuery);

    if (cachedItem) {
        return cachedItem;
    }

    const query = queries[queryName] || queries[queryName + 's'];
    if (!query) {
        console.log(`Function ${queryName} not found`);
        return null;
    }

    let hasError = false;
    const response = await API.graphql({ query, variables: params }).catch(err => {
        hasError = true;
        console.log(err);
    });

    if (response && response.data) {
        const result = response.data[queryName] || response.data[queryName + 's'];
        let decodedResult;
        if (result && result.items && result.items.length) {
            decodedResult = result.items.map(item => objectDecode(item, encodedFields));
        } else if (result && result.id) {
            decodedResult = objectDecode(result, encodedFields);
        } else {
            decodedResult = result && result.items ? [] : null;
        }

        if (!hasError) {
            setCacheItem(cacheKey, decodedResult || '', priority);
        }

        return decodedResult;
    }
};

export const executeQueryWithCallback = async (queryName, params, callback, forceQuery = false) => {
    const result = await executeQuery(queryName, params, forceQuery);

    callback(null, result);
};

export const executeListQueryWithCallBack = async (
    queryName,
    params,
    callback,
    forceQuery = false,
) => {
    const result = await executeListQuery(queryName, params, forceQuery);

    return callback(null, result);
};

export const executeListQuery = async (
    queryName,
    params,
    forceQuery = false,
    keepEventInParams = false,
    additionalFilter = null,
    priority = 5,
) => {
    if (isEmpty(queryName) || isEmpty(params)) {
        return null;
    }

    const cacheKey = `${queryName}_${JSON.stringify(params)}`;
    const cachedItem = await getCacheItem(cacheKey, forceQuery);

    if (cachedItem) {
        return cachedItem;
    }

    const query = queries[queryName] || queries[queryName + 's'];
    if (!query) {
        console.log(`Function ${queryName} not found`);
        return null;
    }

    const localConfig = (await getLocalAppStateAsync()) || {};
    const event = localConfig.id;

    const results = [];
    let nextToken = null;
    let hasError = false;

    do {
        if (!keepEventInParams) {
            // in case there are filter queries that still add the event, just so the query won't fail
            delete params.event;
        }
        const response = await API.graphql({
            query,
            variables: {
                event,
                nextToken,
                limit: 1000,
                ...(!isEmpty(params) && { filter: params }),
            },
        }).catch(err => {
            hasError = true;
            return null;
        });

        if (response && response.data) {
            nextToken = response.data.nextToken;

            const result = response.data[queryName] || response.data[queryName + 's'];
            let decodedItems = result.items.map(item => objectDecode(item, encodedFields));
            if (
                additionalFilter &&
                additionalFilter.byLocationId &&
                additionalFilter.byLocationId.length
            ) {
                decodedItems = decodedItems.filter(timeslot =>
                    timeslot.locations.some(
                        location => location._id === additionalFilter.byLocationId,
                    ),
                );
            }

            nextToken = result.nextToken;

            results.push(...decodedItems);
        } else {
            nextToken = null;
        }
    } while (nextToken);

    if (!hasError) {
        setCacheItem(cacheKey, results || '', priority);
    }

    return results;
};
