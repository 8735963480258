import React, { useCallback, useMemo } from 'react';
import { isKeyHotkey } from 'is-hotkey';
import { Editable, withReact, Slate } from 'slate-react';
import { Editor, Transforms, createEditor, Element as SlateElement, Text, Range } from 'slate';
import { withHistory } from 'slate-history';
import { styled } from '@mui/material/styles';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Button from '@mui/material/Button';
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import LinkEditor from './LinkEditor';
import useSelection from './useSelection';
import LinkIcon from '@mui/icons-material/Link';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import escapeHtml from 'escape-html';
import { unwrapLink } from './utils';

import { SECONDARY_DARK, COLOR_TEXT_LIGHT, COLOR_HOVER } from '../General/Variables';
import useEditorConfig from './useEditorConfig';
import { jsx } from 'slate-hyperscript';
import Stack from '@mui/material/Stack';

const HOTKEYS = {
    'mod+b': 'bold',
    'mod+i': 'italic',
    'mod+u': 'underline',
    'mod+`': 'code',
};

const HEADINGS_MAP = {
    'heading-one': 'Heading 1',
    'heading-two': 'Heading 2',
    'heading-three': 'Heading 3',
    'heading-four': 'Heading 4',
    'heading-five': 'Heading 5',
    'heading-six': 'Heading 6',
};

const TEXT_ALIGN_TYPES = ['left', 'center', 'right', 'justify'];
const LIST_TYPES = ['numbered-list', 'bulleted-list'];
const FORMAT_TYPES = ['bold', 'italic', 'underline'];
const HEADING_TYPES = Object.keys(HEADINGS_MAP).map(key => key);

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
    '& .MuiToggleButtonGroup-grouped': {
        margin: theme.spacing(0.5),
        border: 0,
        '&.Mui-disabled': {
            border: 0,
        },
        '&.Mui-selected': {
            backgroundColor: SECONDARY_DARK,
        },
        '&:not(:first-of-type)': {
            borderRadius: theme.shape.borderRadius,
        },
        '&:first-of-type': {
            borderRadius: theme.shape.borderRadius,
        },
    },
}));

const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
    width: '40px',
    height: '40px',
}));

const StyledButton = styled(Button)(({ theme }) => ({
    height: '40px',
    margin: theme.spacing(0.5),
    color: COLOR_TEXT_LIGHT,
    '&:hover': {
        backgroundColor: COLOR_HOVER,
    },
}));

const serialize = node => {
    if (Text.isText(node)) {
        let string = escapeHtml(node.text);
        if (node.bold) {
            string = `<strong>${string}</strong>`;
        }
        if (node.italic) {
            string = `<em>${string}</em>`;
        }
        if (node.underline) {
            string = `<u>${string}</u>`;
        }

        return string;
    }

    const children = node.children.map(n => serialize(n)).join('');
    switch (node.type) {
        case 'quote':
            return `<blockquote><p>${children}</p></blockquote>`;
        case 'paragraph':
            return `<p>${children}</p>`;
        case 'link':
            return `<a href="${escapeHtml(node.url)}">${children}</a>`;
        case 'bulleted-list':
            return `<ul>${children}</ul>`;
        case 'numbered-list':
            return `<ol>${children}</ol>`;
        case 'list-item':
            return `<li>${children}</li>`;
        case 'heading-one':
            return `<h1>${children}</h1>`;
        case 'heading-two':
            return `<h2>${children}</h2>`;
        case 'heading-three':
            return `<h3>${children}</h3>`;
        case 'heading-four':
            return `<h4>${children}</h4>`;
        case 'heading-five':
            return `<h5>${children}</h5>`;
        case 'heading-six':
            return `<h6>${children}</h6>`;
        case 'image':
            if (node.link) {
                return `<a href="${escapeHtml(node.link)}" target="_blank"><img src="${escapeHtml(
                    node.url,
                )}" width=${node.width} alt=${node.alt || ''}>${children}</img></a>`;
            } else {
                return `<img src="${escapeHtml(node.url)}" width=${node.width} alt=${
                    node.alt || ''
                }>${children}</img>`;
            }

        default:
            return children;
    }
};

const HtmlEditor = ({ onChange, reset = false, document }) => {
    const editor = useMemo(() => withHistory(withReact(createEditor())), []);

    const { renderLeaf, renderElement } = useEditorConfig(editor);

    const [formats, setFormats] = React.useState([]);
    const [list, setList] = React.useState('');
    const [link, setLink] = React.useState('');
    const [newLink, setNewLink] = React.useState(false);

    const [previousSelection, selection, setSelection] = useSelection(editor);

    const onChangeLocal = useCallback(
        doc => {
            const html = doc.map(n => serialize(n)).join('');
            onChange(doc, html);
            setSelection(editor.selection);
        },
        [onChange, setSelection, editor],
    );

    const parentDiv = React.useRef(null);
    const handleFormat = (event, newFormats) => {
        FORMAT_TYPES.forEach(format => {
            if (newFormats.includes(format)) {
                Editor.addMark(editor, format, true);
            } else {
                Editor.removeMark(editor, format);
            }
        });
        setFormats(newFormats);
    };

    const onKeyDown = event => {
        const { selection } = editor;

        // Default left/right behavior is unit:'character'.
        // This fails to distinguish between two cursor positions, such as
        // <inline>foo<cursor/></inline> vs <inline>foo</inline><cursor/>.
        // Here we modify the behavior to unit:'offset'.
        // This lets the user step into and out of the inline without stepping over characters.
        // You may wish to customize this further to only use unit:'offset' in specific cases.
        if (selection && Range.isCollapsed(selection)) {
            const { nativeEvent } = event;
            if (isKeyHotkey('left', nativeEvent)) {
                event.preventDefault();
                Transforms.move(editor, { unit: 'offset', reverse: true });
                return;
            }
            if (isKeyHotkey('right', nativeEvent)) {
                event.preventDefault();
                Transforms.move(editor, { unit: 'offset' });
                return;
            }
            if (isKeyHotkey('shift+enter', nativeEvent)) {
                event.preventDefault();
                addText(editor, '\n');
                return;
            }
        }
    };

    const handleList = (event, newList) => {
        LIST_TYPES.forEach(format => {
            if (newList === format) {
                toggleBlock(editor, format, true);
            } else {
                toggleBlock(editor, format, false);
            }
        });
        setList(newList);
    };

    const handleLink = (event, newLink) => {
        if (newLink === 'link') {
            setNewLink(true);
        } else {
            setNewLink(false);
            unwrapLink(editor);
        }
        setLink(newLink);
    };

    React.useEffect(() => {
        const formats = [];

        FORMAT_TYPES.forEach(format => {
            if (isMarkActive(editor, format)) {
                formats.push(format);
            }
        });

        setFormats(formats);
        setNewLink(false);
    }, [selection, previousSelection]);

    React.useEffect(() => {
        if (reset) {
            Transforms.delete(editor, {
                at: {
                    anchor: Editor.start(editor, []),
                    focus: Editor.end(editor, []),
                },
            });

            // Removes empty node
            Transforms.removeNodes(editor, {
                at: [0],
            });

            // Insert array of children nodes
            Transforms.insertNodes(editor, document);
            // ReactEditor.focus(editor);
            // Transforms.move(editor, { unit: 'offset' });
        }
    }, [reset]);

    return (
        <Slate editor={editor} value={initialValue} onChange={onChangeLocal}>
            <Box
                ref={parentDiv}
                sx={{
                    flexGrow: 1,
                    backgroundColor: 'rgba(0, 0, 0, 0.06)',
                    p: '12px',
                    border: '2px solid white',
                    borderRadius: '6px',
                    minHeight: '200px',
                }}
            >
                <Stack direction="row" marginBottom={1} marginX={-0.5} marginTop={-0.5}>
                    <StyledToggleButtonGroup
                        value={formats}
                        onChange={handleFormat}
                        aria-label="text alignment"
                    >
                        <StyledToggleButton value="bold" aria-label="bold">
                            <FormatBoldIcon />
                        </StyledToggleButton>
                        <StyledToggleButton value="italic" aria-label="italic">
                            <FormatItalicIcon />
                        </StyledToggleButton>
                        <StyledToggleButton value="underline" aria-label="underline">
                            <FormatUnderlinedIcon />
                        </StyledToggleButton>
                    </StyledToggleButtonGroup>
                    <Divider
                        flexItem
                        variant="middle"
                        orientation="vertical"
                        sx={{
                            borderColor: SECONDARY_DARK,
                            margin: 0,
                        }}
                    />
                    <StyledToggleButtonGroup
                        value={list}
                        exclusive
                        onChange={handleList}
                        aria-label="lists"
                    >
                        <StyledToggleButton value="bulleted-list" aria-label="bulleted-list">
                            <FormatListBulletedIcon />
                        </StyledToggleButton>
                        <StyledToggleButton value="numbered-list" aria-label="numbered-list">
                            <FormatListNumberedIcon />
                        </StyledToggleButton>
                    </StyledToggleButtonGroup>
                    <Divider
                        flexItem
                        variant="middle"
                        orientation="vertical"
                        sx={{
                            borderColor: SECONDARY_DARK,
                            margin: 0,
                        }}
                    />
                    <StyledToggleButtonGroup
                        value={link}
                        exclusive
                        onChange={handleLink}
                        aria-label="link"
                    >
                        <StyledToggleButton value="link" aria-label="link">
                            <LinkIcon />
                        </StyledToggleButton>
                    </StyledToggleButtonGroup>
                </Stack>
                <LinkEditor targetSelection={selection} setLink={setLink} newLink={newLink} />
                <Editable
                    renderElement={renderElement}
                    renderLeaf={renderLeaf}
                    placeholder="Add description (optional)"
                    renderPlaceholder={({ children, attributes }) => (
                        <span
                            {...attributes}
                            style={{
                                ...attributes.style,
                                opacity: 1,
                                color: 'rgba(0, 0, 0, 0.6)',
                            }}
                        >
                            Add description (optional)
                        </span>
                    )}
                    spellCheck
                    onKeyDown={onKeyDown}
                />
            </Box>
        </Slate>
    );
};

const toggleBlock = (editor, format, makeActive = true) => {
    const isActive = isBlockActive(editor, format);
    const isList = LIST_TYPES.includes(format);

    if (!makeActive && !isActive) {
        return;
    }

    Transforms.unwrapNodes(editor, {
        match: n => {
            return !Editor.isEditor(n) && SlateElement.isElement(n) && LIST_TYPES.includes(n.type);
        },
        split: true,
    });

    let newProperties;
    newProperties = {
        type: !makeActive ? 'paragraph' : isList ? 'list-item' : format,
    };

    Transforms.setNodes(editor, newProperties);

    if (makeActive && isList) {
        const block = { type: format, children: [] };
        Transforms.wrapNodes(editor, block);
    }
};

const addText = (editor, text) => {
    Transforms.insertText(editor, text);
};

const isBlockActive = (editor, format, blockType = 'type') => {
    const { selection } = editor;
    if (!selection) return false;

    const [match] = Array.from(
        Editor.nodes(editor, {
            at: Editor.unhangRange(editor, selection),
            match: n => !Editor.isEditor(n) && SlateElement.isElement(n) && n[blockType] === format,
        }),
    );

    return !!match;
};

const isMarkActive = (editor, format) => {
    const marks = Editor.marks(editor);
    return marks ? marks[format] === true : false;
};

export const createInfoRichTextInitialValue = text => {
    const rich = [
        {
            type: 'paragraph',
            children: [{ text }],
        },
    ];
    return rich;
};

export const deserializeFromHtml = (el, markAttributes = {}) => {
    if (el.nodeType === Node.TEXT_NODE) {
        return jsx('text', markAttributes, el.textContent);
    } else if (el.nodeType !== Node.ELEMENT_NODE) {
        return null;
    }

    const nodeAttributes = { ...markAttributes };

    // define attributes for text nodes
    switch (el.nodeName) {
        case 'STRONG':
            nodeAttributes.bold = true;
            break;
        case 'EM':
            nodeAttributes.italic = true;
            break;
        case 'U':
            nodeAttributes.underline = true;
            break;
        default:
    }

    const children = Array.from(el.childNodes)
        .map(node => deserializeFromHtml(node, nodeAttributes))
        .flat();

    if (children.length === 0) {
        children.push(jsx('text', nodeAttributes, ''));
    }

    switch (el.nodeName) {
        case 'BODY':
            return jsx('fragment', {}, children);
        case 'BR':
            return '\n';
        case 'BLOCKQUOTE':
            return jsx('element', { type: 'quote' }, children);
        case 'P':
            return jsx('element', { type: 'paragraph' }, children);
        case 'DIV':
            if (el.innerText.trim().length > 0) {
                return jsx('element', { type: 'paragraph' }, children);
            } else {
                return children;
            }

        case 'H1':
            return jsx('element', { type: 'heading-one' }, children);
        case 'H2':
            return jsx('element', { type: 'heading-two' }, children);
        case 'H3':
            return jsx('element', { type: 'heading-three' }, children);
        case 'H4':
            return jsx('element', { type: 'heading-four' }, children);
        case 'H5':
            return jsx('element', { type: 'heading-five' }, children);
        case 'H6':
            return jsx('element', { type: 'heading-six' }, children);
        case 'UL':
            return jsx('element', { type: 'bulleted-list' }, children);
        case 'OL':
            return jsx('element', { type: 'numbered-list' }, children);
        case 'LI':
            return jsx('element', { type: 'list-item' }, children);
        case 'A':
            if (el.childNodes.length === 1 && el.childNodes[0].nodeName === 'IMG') {
                const imageNode = el.childNodes[0];
                const emptyChildren = [jsx('text', nodeAttributes, '')];
                return jsx(
                    'element',
                    {
                        type: 'image',
                        url: imageNode.getAttribute('src'),
                        width: imageNode.getAttribute('width') || '',
                        alt: imageNode.getAttribute('alt') || '',
                        link: el.getAttribute('href'),
                    },
                    emptyChildren,
                );
            }
            return jsx('element', { type: 'link', url: el.getAttribute('href') }, children);
        case 'IMG':
            return jsx(
                'element',
                {
                    type: 'image',
                    url: el.getAttribute('src'),
                    width: el.getAttribute('width'),
                    alt: el.getAttribute('alt'),
                },
                children,
            );
        default:
            return children;
    }
};

const initialValue = [
    {
        type: 'paragraph',
        children: [{ text: '' }],
    },
];

export default HtmlEditor;
