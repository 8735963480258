import React, { useEffect, useMemo, useState } from 'react';
import { FontIcon } from 'react-md';

import styled from 'styled-components';

import NavigationBar from '../DetailHeader/NavigationBar';
import Button, { buttonTypes } from '../../scenes/common/Button';
import { getString } from '../../services/api/store';
import * as palette from '../General/Variables';
import { Flex } from '../../scenes/common/Flex';
import { ModalContent } from '../Dialog/styles';
import BasicDialog from '../Dialog/BasicDialog';
import { DetailDrawer } from '../General/DetailDrawer';
import eventBus from '../../utils/eventBus';
import IconButton from '@mui/material/IconButton';
import { COLOR_TEXT } from '../General/Variables';
import HtmlEditor, { deserializeFromHtml } from '../HtmlEditor/HtmlEditor';

const NotePageContainer = styled.div`
    padding: 16px;
    background: #fff !important;
`;

const defaultInfoRichText = [
    {
        type: 'paragraph',
        children: [{ text: '' }],
    },
];

const DropdownWrapper = styled(IconButton)`
    color: ${COLOR_TEXT} i {
        font-size: 24px !important;
    }
`;

const StyledDetailDrawer = styled(DetailDrawer)`
    z-index: 10;
    background: #fff !important;
`;

const EditorContainer = styled.div`
    border-radius: 8px;
    overflow: hidden;
    div {
        border-color: #dddddd !important;
        & > * {
            color: rgba(0, 0, 0, 0.87) !important;
            font-size: 15px;
        }

        .jodit-ui-group {
            width: 100%;
            display: flex !important;
            justify-content: space-around !important;
        }
    }
`;

const ButtonsContainer = styled(Flex)`
    background: #fff !important;
`;

// function to check if text contains strings not wrapped in a html tag
// if not, wrap it in a <p> tag
// if it is, return it as is
// this is to prevent the editor from breaking because text is not actually html
const wrapInPTag = text => {
    const firstTagIndex = text.indexOf('<');

    if (firstTagIndex >= 0) {
        const untagged = text.substring(0, firstTagIndex);
        const rest = text.substring(firstTagIndex);
        if (untagged.length) {
            return `<p>${untagged}</p>${rest && rest.length ? rest : ''}`;
        } else {
            return text;
        }
    }

    return `<p>${text}</p>`;
};

export const NotesPage = ({
    text,
    note,
    setText,
    addOrEditNote,
    setShowNotesScreen,
    theme,
    setUpdateNote,
}) => {
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [resetEditor, setResetEditor] = useState(false);
    const [infoRichText, setInfoRichText] = useState(defaultInfoRichText);
    const NOTE_TITLE = getString('addNote') || 'NOTE';
    const TEXT_CANCEL_BUTTON = getString('noteCancelButtonTitle') || 'Cancel';
    const TEXT_SAVE_BUTTON = getString('noteSaveButtonTitle') || 'Save';
    const DELETE_NOTE_DIALOG_TEXT = getString('noteDeleteButtonTitle') || 'Delete';
    const CANCEL_NOTE_DIALOG_TEXT = getString('notesDeleteConfirmationCancel') || 'Cancel';
    const DELETE_NOTE_DIALOG_TITLE = getString('noteDeleteDialogTitle') || 'Delete note';
    const DELETE_NOTE_DIALOG_DESCRIPTION =
        getString('notesDeleteConfirmationDescription') ||
        'Are you sure you want to delete this note? Once deleted, it cannot be retrieved.';

    const infoDocument = useMemo(() => {
        const txt = wrapInPTag(text);
        return new window.DOMParser().parseFromString(txt || '', 'text/html');
    }, [note?.text]);

    useEffect(() => {
        setResetEditor(false);
        if (infoDocument && infoDocument.body) {
            const richText = deserializeFromHtml(infoDocument.body);
            setInfoRichText(richText);
        }
    }, [infoDocument]);

    useEffect(() => {
        if (note?.text) {
            setResetEditor(true);
        }
    }, [note?.text]);

    const onClose = () => {
        setShowNotesScreen(false);
        eventBus.emit('notesPageClosed');
    };

    return (
        <StyledDetailDrawer>
            <NavigationBar
                background={palette.COLOR_WHITE}
                typeName={NOTE_TITLE}
                goBack={onClose}
                customRightButton={
                    text !== '' && (
                        <DropdownWrapper
                            aria-label="Delete note"
                            onClick={() => setShowDeleteConfirmation(true)}
                            onKeyDown={e => {
                                if (e.key === 'Enter') {
                                    setShowDeleteConfirmation(true);
                                }
                            }}
                        >
                            <FontIcon
                                iconClassName="material-icons-outlined"
                                style={{ color: COLOR_TEXT }}
                            >
                                delete
                            </FontIcon>
                        </DropdownWrapper>
                    )
                }
            />
            <BasicDialog
                title={DELETE_NOTE_DIALOG_TITLE}
                visible={showDeleteConfirmation}
                onClose={() => setShowDeleteConfirmation(false)}
                options={[
                    {
                        title: DELETE_NOTE_DIALOG_TEXT,
                        variant: 'text',
                        type: 'delete',
                        onClick: () => {
                            setText('');
                            addOrEditNote('');
                            setUpdateNote(true);
                        },
                    },
                    {
                        title: CANCEL_NOTE_DIALOG_TEXT,
                        variant: 'text',
                        type: 'cancel',
                        onClick: () => {
                            setShowDeleteConfirmation(false);
                        },
                    },
                ]}
            >
                <ModalContent textAlign={'start'}>{DELETE_NOTE_DIALOG_DESCRIPTION}</ModalContent>
            </BasicDialog>
            <NotePageContainer>
                <EditorContainer>
                    <HtmlEditor
                        onChange={(value, html) => {
                            setInfoRichText(value);
                            setText(html);
                        }}
                        reset={resetEditor}
                        document={infoRichText}
                    />
                </EditorContainer>

                <ButtonsContainer>
                    <Button
                        type={buttonTypes.GREEN_LONG}
                        background={theme.primary}
                        text={TEXT_SAVE_BUTTON}
                        onClick={() => {
                            addOrEditNote(text);
                            if (setUpdateNote) {
                                setUpdateNote(true);
                            }
                        }}
                        style={{ marginTop: '16px', marginRight: '8px' }}
                    />
                    <Button
                        type={buttonTypes.GREY_LONG}
                        background={theme.primary}
                        text={TEXT_CANCEL_BUTTON}
                        onClick={onClose}
                        style={{ marginTop: '16px' }}
                    />
                </ButtonsContainer>
            </NotePageContainer>
        </StyledDetailDrawer>
    );
};
