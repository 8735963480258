import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Button, { buttonTypes } from '../../common/Button';
import NavigationLink from '../../../components/Navigation/NavigationLink';
import { useTheme } from '../../../components/Theme/ThemeContext';
import { withRouter, useHistory } from 'react-router-dom';

import { DetailDrawer } from '../../../components/General/DetailDrawer';
import Stack from '@mui/material/Stack';
import { ArrowBack } from '@mui/icons-material';
import { IconButton, Typography } from '@mui/material';

const StyledDetailDrawer = styled(DetailDrawer)`
    overflow: hidden;
    z-index: 10;
    padding: 16px;
`;

const Image = styled.img`
    width: 48px;
    height: 48px;
    border-radius: 4px;
`;

const BoothInfoCard = ({ item, exhibitor }) => {
    const history = useHistory();
    const { theme } = useTheme();

    if (!exhibitor) {
        return null;
    }

    const { imageUrl } = exhibitor;

    return (
        <StyledDetailDrawer>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
                <Stack direction="row" alignItems="center" spacing={3}>
                    <IconButton
                        onClick={() => history.goBack()}
                        style={{
                            minWidth: '40px',
                            minHeight: '40px',
                            height: '40px',
                            marginLeft: '-8px',
                            marginRight: '-8px',
                        }}
                    >
                        <ArrowBack />
                    </IconButton>
                    {imageUrl && <Image src={imageUrl} />}
                    <Stack direction="column" spacing={0}>
                        <Typography variant="h6">{exhibitor.name}</Typography>
                        <Typography variant="body2">{exhibitor.subNameDetail}</Typography>
                    </Stack>
                </Stack>
                <NavigationLink path={`/exhibitorbooth/${exhibitor.id}`}>
                    <Button
                        type={buttonTypes.GREEN}
                        text="Visit booth"
                        background={theme.primary}
                        onClick={() => {}}
                        style={{ padding: 0 }}
                    />
                </NavigationLink>
            </Stack>
        </StyledDetailDrawer>
    );
};

export default withRouter(BoothInfoCard);
