import { create } from 'zustand';
import {
    getRegisteredSessions,
    getRegistrationSessionCapacity,
    syncExternalRegistrations,
    updateSessionRegistration,
} from '../services/sessionRegistration';

const INITIAL_CAPACITY = {
    enabled: false,
    registeredUsersCount: 0,
    isFull: false,
    total: 0,
};

export const useSessionRegistrationStore = create((set, get) => ({
    registeredSessions: [],
    activeSessionCapacity: INITIAL_CAPACITY,
    getRegisteredSessions: async eventId => {
        const result = await getRegisteredSessions(eventId);
        set({ registeredSessions: result });
    },
    syncExternalRegistrations: async (eventId, externalSyncBrand) => {
        await syncExternalRegistrations(eventId, externalSyncBrand);
    },
    resetRegisteredSessions: () => {
        set({ registeredSessions: [] });
    },
    updateSessionRegistration: async (eventId, sessionId) => {
        const sessions = [...get().registeredSessions];
        const found = sessions.find(session => session.reference === sessionId);
        const action = found?.id ? 'Delete' : 'Add';

        const result = await updateSessionRegistration(eventId, sessionId, { action });

        if (result && result.action === 'Delete') {
            const registeredSessions = sessions.filter(item => item.id !== result.id);
            set({ registeredSessions });
        } else {
            sessions.push(result);
            set({ registeredSessions: sessions });
        }
    },
    setRegistrationSessionCapacity: async (eventId, sessionId, registrationRoomCapacity) => {
        const info = { ...get().activeSessionCapacity };

        if (!registrationRoomCapacity) {
            return info;
        }

        const result = await getRegistrationSessionCapacity(eventId, sessionId);

        info.enabled = true;
        info.registeredUsersCount = result?.count || 0;
        info.isFull = parseInt(registrationRoomCapacity, 10) === parseInt(result?.count || 0, 10);
        info.total = registrationRoomCapacity;
        set({ activeSessionCapacity: info });
    },
    resetRegistrationSessionCapacity: () => {
        set({ activeSessionCapacity: INITIAL_CAPACITY });
    },
}));
