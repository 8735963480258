import React, { useContext } from 'react';
import { useMediaQuery } from '@mui/material';
import { useSelector } from 'react-redux';
import { FontIcon } from 'react-md';
import moment from 'moment';
import styled from 'styled-components';

import useSocietySettings from '../../../Society/hooks/useSocietySettings';
import StartEndTime from '../../../../components/General/StartEndTime';
import * as palette from '../../../../components/General/Variables';
import AuthButton from '../../../User/containers/AuthButton';
import { TimezoneContext } from '../../../Timezone/context';
import IconButton from '@mui/material/IconButton';

import { getEvents } from '../../../Society/selectors';

const Wrapper = styled.div`
    align-items: center;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.2);
    background-color: #ffffff;
    display: flex;
    flex-flow: row;
    height: 56px;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    padding: 0 16px 0 0 !important;
    z-index: 4;
    ${props => !props.nozindex && 'z-index: 16;'}
`;

const Icon = styled(FontIcon)`
    vertical-align: top;
    color: rgba(0, 0, 0, 0.87) !important;
    font-size: 20px;
    cursor: pointer;
`;

const TextWrapper = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
`;

const SessionInfo = styled.div`
    display: flex;
    flex-direction: column;
    flex: 3;
    overflow: hidden;
    margin-left: 20px;
    height: 100%;

    @media only screen and (max-width: ${palette.MAX_PHONE}) {
        margin-left: 12px;
    }
`;

const SessionName = styled.div`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-family: 'Cabin', sans-serif;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: rgba(0, 0, 0, 0.87);
    margin-left: 12px;
    font-weight: 700;
    font-size: 15px;
    line-height: 20px;
`;

const Header = ({ goBack, timeslot, newVersionAvailable, updateServiceWorker, noZIndex }) => {
    const { isSociety } = useSocietySettings();
    const events = useSelector(getEvents);
    const current = events.find(ev => ev._id === timeslot.event);

    let startTime;
    let endTime;

    const timezoneContext = useContext(TimezoneContext);

    if (isSociety) {
        let startMoment = moment.utc(timeslot.start);
        let endMoment = moment.utc(timeslot.end);
        if (current?.hasStudioReleases && current?.timezone) {
            startMoment = startMoment.tz(current?.timezone);
            endMoment = endMoment.tz(current?.timezone);
        }
        startTime = startMoment.format('HH:mm');
        endTime = endMoment.format('HH:mm');
    } else {
        const { getUtcToSelectedTimezone, getUnixToSelectedTimezone } = timezoneContext;
        const startMoment =
            timeslot?.type === 'appointment'
                ? getUnixToSelectedTimezone(timeslot.start)
                : getUtcToSelectedTimezone(timeslot.start);
        const endMoment =
            timeslot?.type === 'appointment'
                ? getUnixToSelectedTimezone(timeslot.end)
                : getUtcToSelectedTimezone(timeslot.end);

        startTime = startMoment.format('HH:mm');
        endTime = endMoment.format('HH:mm');
    }

    const isPhone = useMediaQuery('(max-width:767px)');

    return (
        <Wrapper nozindex={noZIndex ? 1 : 0}>
            <IconButton
                onClick={goBack}
                style={{ marginLeft: 20, minWidth: 40, minHeight: 40, height: 40, width: 40 }}
            >
                <Icon>{palette.ICON_BACK}</Icon>
            </IconButton>
            <SessionInfo desktopSize={9}>
                <TextWrapper>
                    {timeslot?.start && !isPhone && (
                        <StartEndTime text={`${startTime} - ${endTime}`} isVirtual />
                    )}
                    <SessionName>{timeslot.name}</SessionName>
                </TextWrapper>
            </SessionInfo>
            <AuthButton
                light
                newVersionAvailable={newVersionAvailable}
                updateServiceWorker={updateServiceWorker}
            />
        </Wrapper>
    );
};

export default Header;
