// COLORS
export const COLOR_PRIMARY = '#673ab7';
export const COLOR_SECONDARY = '#7E57C2';
export const COLOR_SECONDARY_MEDIUM = '#F0F0F0';
export const COLOR_CONTRAST = '#f6a738';
export const COLOR_WHITE = '#FFFFFF';
export const COLOR_GREY = '#B3B3B3';
export const COLOR_HOVER = 'rgba(0, 0, 0, 0.04)';
export const SECONDARY_DARK = '#D3D3D3';
export const COLOR_LIGHT_GREY = 'rgba(211, 211, 211, 0.41)';
export const COLOR_TEXT = 'rgba(0, 0, 0, 0.87)';
export const COLOR_TEXT_LIGHT = 'rgba(0, 0, 0, 0.54)';
export const COLOR_TEXT_DISABLED = 'rgba(0, 0, 0, 0.38)';
export const BLACK_87 = 'rgba(0, 0, 0, .87)';
export const BLACK_60 = 'rgba(0, 0, 0, .60)';

export const UI_GREY_1 = '#f5f5f5';
export const UI_GREY_2 = '#c4c4c4';
export const UI_GREY_3 = '#d9d9d9';
export const UI_GREY_4 = '#e2e2e2';
export const UI_GREY_5 = '#666666';
export const UI_GREY_6 = '#e5e5e5';

export const WHITE = '#FFFFFF';
export const RED_3 = '#ec0046';

export const COLOR_ERROR = '#EE1060';
export const COLOR_SELECTED = 'rgba(243,240,254)';
export const COLOR_ERROR_SELECTED = 'rgba(255,244,248)';

export const SOCIETY_COLORS = {
    PRIMARY: '#322EF4',
    BRANDING: '#170A7C',
    WHITE: '#FFF',
};

export const ELEVATION = '0 1px 2px 0 rgba(0, 0, 0, 0.3)';
// DIMENSIONS
export const MIN_PHONE_XS = '320px';
export const MAX_PHONE_XS = '428px';
export const MIN_PHONE = '575px';
export const MAX_PHONE = '767px';
export const MIN_TABLET = '768px';
export const MAX_TABLET = '1024px';
export const MIN_DESKTOP = '1025px';
export const MAX_DESKTOP = '1366px';
export const MIN_LARGE_DESKTOP = '1367px';
export const MAX_SMALL_PHONE_INT = 599;
export const MIN_PHONE_XS_INT = 320;
export const MAX_PHONE_XS_INT = 428;
export const MAX_PHONE_INT = 767;
export const MIN_TABLET_INT = 768;
export const MAX_TABLET_INT = 1024;
export const MIN_DESKTOP_INT = 1025;
export const MAX_DESKTOP_INT = 1366;
export const MIN_LARGE_DESKTOP_INT = 1367;

export const DIALOG_HEIGHT = 600;
export const DIALOG_WIDTH = 360;

// ICONS
export const ICON_BACK = 'arrow_back';
export const ICON_CLOSE = 'clear';
export const ICON_CONVERSATION = 'forum';
export const ICON_FLOORPLAN = 'map';
export const ICON_INFO = 'info_outline';
export const ICON_INSTITUTION = 'business';
export const ICON_PERSON = 'account_circle';
export const ICON_PERSON_OUTLINE = 'account_circle_outline';
export const ICON_PLACE = 'place';
export const ICON_POSTER = 'insert_drive_file';
export const ICON_PROFILE = 'person';
export const ICON_PROGRAMME = 'insert_invitation';
export const ICON_SIGN_OUT = 'exit_to_app';
export const ICON_SEARCH = 'search';
export const ICON_SPEAKERS = 'people';
export const ICON_TALK = 'forum';
export const ICON_ARROW_OPEN_DROPDOWN = 'arrow_drop_down';
export const ICON_EXPAND_MORE = 'expand_more';
export const ICON_EXPAND_LESS = 'expand_less';
export const ICON_FILTER_LIST = 'filter_list';
export const ICON_CHECK = 'check';
export const ICON_VIDEO = 'check';
export const ICON_GLOBE = 'language';
export const ICON_MAIL_OUTLINE = 'mail_outline';
export const ICON_MAIL = 'mail';
export const ICON_PHONE_OUTLINE = 'phone_outline';
export const ICON_LOCATION_OUTLINE = 'location_on_outline';
export const ICON_CITY = 'location_city';
export const ICON_ADD_CIRCLE = 'add_circle_outline';
export const ICON_EVENT = 'event';
export const ICON_ACCESS_TIME = 'access_time';
export const ICON_LOCATION_ON = 'location_on';
export const ICON_PERSON__OUTLINE = 'person_outline';
export const ICON_NOTES = 'notes';
export const ICON_CAMERA = 'videocam_outline';
export const ICON_CAMERA_OFF = 'videocam_off_outline';
export const ICON_MIC = 'mic';
export const ICON_MIC_OFF = 'mic_off';
export const STAR = 'star';
export const STAR_OUTLINE = 'star_border';
export const ICON_CHAT_OUTLINE = 'chat_bubble_outline';
export const ICON_CHAT = 'chat_bubble';
export const QA_ICON_OUTLINE = 'forum';
export const QA_ICON = 'forum';
export const PEOPLE_ICON_OUTLINE = 'people_outline';
export const PEOPLE_ICON = 'people';
export const HELP_ICON_OUTLINE = 'help_outline';
export const ASSIGNMENT_ICON = 'assignment';
export const THUMB_UP_ICON = 'thumb_up';
export const EMAIL_ICON = 'email';
export const CHECK_BOX_ICON = 'check_box';
export const PDF_ICON = 'picture_as_pdf';
export const FILE_PRESENT_ICON = 'file_present';
export const ADD_PHOTO = 'photo_camera';
export const BUSINESS_CENTER = 'business_center';
export const ICON_PEN_EDIT = 'edit';
export const ICON_GAVEL = 'gavel';
export const ICON_CANCEL = 'cancel';
export const AIRPLANE = 'airplanemode_active';
export const ICON_EYE = 'remove_red_eye';
export const ICON_CLOUD_DOWNLOAD = 'cloud_download';
export const ICON_ADD_PERSON = 'person_add';
export const ICON_REMOVE_PERSON = 'person_add_disabled';
export const ICON_ADD = 'add';
export const ICON_SHARE = 'share';
export const ICON_VIDEOCAM = 'videocam';
