import React, { useCallback, useEffect, useState } from 'react';
import findIndex from 'lodash/findIndex';
import { withRouter, generatePath, useLocation } from 'react-router-dom';
import NavigationLink, { navigateTo } from '../../components/Navigation/NavigationLink';
import styled from 'styled-components';
import Loader from '../../components/General/Loader';
import ThemeContext from '../../components/Theme/ThemeContext';
import Floorplan from './components/floorplan';
import FloorplanList from './components/list';
import { getFloorplan } from './services/floorplan';
import InfoCard from './components/BoothInfoCard';
import {
    TabElement,
    TabsContainerStyled,
    TabsWrapper,
    ToolbarContainer,
} from '../Programme/style/style';
import { useMediaQuery } from '@mui/material';
import * as palette from '../../components/General/Variables';
import {
    usePlaceByFloorplanAndIdWithInstitution,
    usePlacesByFloorplan,
    usePlacesStore,
    useGetPlacesByFloorplan,
} from '../../stores/PlacesStore';
import { useInstitutionsStore } from '../../stores/InstitutionsStore';

const StyledLink = styled(NavigationLink)`
    font-weight: 500;
`;

const FloorplanPage = props => {
    const [floorplans, setFloorplans] = useState([]);
    const [selectedTab, setSelectedTab] = useState(0);
    const [loading, setLoading] = useState(true);
    const [sections, setSections] = useState([]);
    const { pageId, match } = props;
    const isTablet = useMediaQuery(`(max-width: ${palette.MIN_TABLET_INT}px)`);
    const currentActivePlace = usePlaceByFloorplanAndIdWithInstitution(
        match.params.floorplan,
        match.params.place,
    );
    const allPlaces = usePlacesStore(state => state.placesByFloorplan);
    const getPlacesByFloorplan = useGetPlacesByFloorplan();
    const fetchInstitution = useInstitutionsStore(state => state.fetchInstitution);
    function useQuery() {
        return new URLSearchParams(props.location.search);
    }
    let query = useQuery();
    const showDrawer = Boolean(query.get('showDrawer'));
    const currentActivePlaceHasBoothInstitution =
        currentActivePlace?.institution?.typeEntity?.detailPageLayout !== 'panel';

    useEffect(() => {
        getFloorplan(props.pageId, async (err, result) => {
            if (result && result.page && result.floorplans && result.floorplans.length) {
                setFloorplans(result.floorplans);
                for (const floorplan of result.floorplans) {
                    await getPlacesByFloorplan(floorplan.id);
                }
                const sections =
                    result.page.sections && result.page.sections.length
                        ? result.page.sections
                        : result.page.params.sections;
                setSections(sections);
                setLoading(false);

                if (!match.params.floorplan) {
                    if (
                        result.floorplans.length &&
                        result.floorplans[result.page.params.initialItem || 0]
                    ) {
                        return props.history.push(
                            `${props.location.pathname}/${
                                result.floorplans[result.page.params.initialItem || 0].id
                            }${match.params.place ? `/${match.params.place}` : ''}`,
                        );
                    }
                }
            }
        });
    }, [pageId, match.params.floorplan, match.params.place]);

    const openDrawerRight = e => {
        return props.history.push(
            `${generatePath(props.match.path, {
                floorplan: match.params.floorplan,
                ...(currentActivePlace?.id && { place: currentActivePlace.id }),
            })}?showDrawer=true`,
        );
    };

    const onDrawerClose = e => {
        return props.history.push(
            `${generatePath(props.match.path, {
                floorplan: match.params.floorplan,
            })}`,
        );
    };

    const onActiveFloorplan = useCallback(
        id => {
            const index = findIndex(floorplans, item => {
                return id === item.id;
            });
            setSelectedTab(index);
        },
        [floorplans],
    );

    return (
        <React.Fragment>
            {loading && <Loader />}
            {!loading && (
                <React.Fragment>
                    <ThemeContext.Consumer>
                        {({ theme }) => (
                            <ToolbarContainer>
                                <TabsContainerStyled
                                    defaultTabIndex={0}
                                    activeTabIndex={selectedTab}
                                    panelClassName="md-grid"
                                    colored
                                    onTabChange={() => {}}
                                >
                                    <TabsWrapper
                                        background={theme.primary}
                                        tabId="floorplan"
                                        mobile
                                        alignToKeyline={false}
                                    >
                                        {floorplans.map((tab, i) => (
                                            <TabElement
                                                key={i}
                                                component={StyledLink}
                                                pathReplaceParams={{
                                                    floorplan: tab.id,
                                                    place: null,
                                                }}
                                                label={tab.name}
                                                primary={theme.primary}
                                                active={selectedTab === i}
                                                onClick={() => {
                                                    this.setState({ selectedTab: i });
                                                }}
                                            />
                                        ))}
                                    </TabsWrapper>
                                </TabsContainerStyled>
                            </ToolbarContainer>
                        )}
                    </ThemeContext.Consumer>
                    {match.params.floorplan && (
                        <>
                            <Floorplan
                                {...props}
                                searchButtonAction={openDrawerRight}
                                onActiveFloorplan={onActiveFloorplan}
                            />
                            {showDrawer && (
                                <FloorplanList
                                    sections={sections}
                                    visible={showDrawer}
                                    onClose={onDrawerClose}
                                    onClick={async item => {
                                        if (isTablet) {
                                            return null;
                                        }
                                        const placeItem = allPlaces[item.floorplan].find(
                                            place => place.id === item.id,
                                        );
                                        const institution = await fetchInstitution(
                                            placeItem?.exhibitorId,
                                        );

                                        if (!institution) {
                                            return props.history.push(
                                                `${generatePath(props.match.path, {
                                                    floorplan: item.floorplan,
                                                    place: placeItem.id,
                                                })}`,
                                            );
                                        }
                                        if (institution?.typeEntity?.detailPageLayout === 'panel') {
                                            return props.history.push(
                                                `${generatePath(props.match.path, {
                                                    floorplan: item.floorplan,
                                                    place: placeItem.id,
                                                })}?objectClass=institution&objectId=${
                                                    placeItem?.exhibitorId
                                                }&type=detail`,
                                            );
                                        }

                                        return props.history.push(
                                            `${generatePath(props.match.path, {
                                                floorplan: item.floorplan,
                                                place: placeItem.id,
                                            })}`,
                                        );
                                    }}
                                />
                            )}
                            {!isTablet &&
                                currentActivePlaceHasBoothInstitution &&
                                currentActivePlace?.institution && (
                                    <InfoCard exhibitor={currentActivePlace.institution} />
                                )}
                        </>
                    )}
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

export default withRouter(FloorplanPage);
