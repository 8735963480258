import React, { useEffect, useState, useRef } from 'react';
import HtmlEditor, { deserializeFromHtml } from './HtmlEditor';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import * as palette from '../../components/General/Variables';
import Modal from '../Modal';

const infoRichText = [
    {
        type: 'paragraph',
        children: [{ text: '' }],
    },
];

const TextArea = styled(TextField)(({ theme }) => ({
    '& .MuiInputBase-root': {
        border: '3px sold green',
        '&.Mui-focused': {
            border: '3px sold green !important',
            backgroundColor: palette.COLOR_LIGHT_GREY,
        },
    },
}));

const CustomEnabledHtmlEditor = props => {
    const { customHtml, onChange, placeholder, custom } = props;
    const [customHtmlDisplay, setCustomHtmlDisplay] = React.useState('');
    const [showPreview, setShowPreview] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState('');

    const [cursor, setCursor] = useState(null);
    const ref = useRef(null);

    useEffect(() => {
        ref.current?.setSelectionRange(cursor, cursor);
    }, [ref, cursor, customHtmlDisplay]);

    useEffect(() => {
        if (!customHtml) {
            setCustomHtmlDisplay('');
            return;
        }

        setCustomHtmlDisplay(customHtml);
    }, [customHtml]);

    const handleClosePreview = () => {
        setShowPreview(false);
    };

    const toggleCustom = custom => {
        if (custom) {
            onChange(infoRichText, customHtmlDisplay, custom);
            setShowConfirmation('');
        } else {
            let richText = infoRichText;
            if (customHtmlDisplay) {
                const cleanHtmlString = customHtmlDisplay.replace(/[\r\n]+/g, '').trim();
                const infoDocument = new window.DOMParser().parseFromString(
                    cleanHtmlString || '',
                    'text/html',
                );
                richText = deserializeFromHtml(infoDocument.body);
            }
            onChange(richText, customHtmlDisplay, custom);
            setShowConfirmation('');
        }
    };

    return (
        <Stack>
            {/* <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                marginBottom={2}
            >
                <Stack direction="row">
                    <Button
                        variant="contained"
                        color={!custom ? 'primary' : 'secondary'}
                        onClick={() => setShowConfirmation('toEditor')}
                        style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                    >
                        Editor
                    </Button>
                    <Button
                        variant="contained"
                        color={custom ? 'primary' : 'secondary'}
                        onClick={() => toggleCustom(true)}
                        style={{ borderBottomLeftRadius: 0, borderTopLeftRadius: 0 }}
                    >
                        Custom HTML
                    </Button>
                </Stack>
                {custom && (
                    <Stack direction="row">
                        <Button
                            variant="contained"
                            color={'primary'}
                            onClick={() => setShowPreview(true)}
                        >
                            Preview
                        </Button>
                    </Stack>
                )}
            </Stack> */}
            {/* {custom && (
                <Stack
                    onMouseEnter={props.onMouseEnter}
                    onMouseLeave={props.onMouseLeave}
                    position="relative"
                >
                    <>
                        <TextArea
                            inputRef={ref}
                            id="custom_html-textarea"
                            placeholder="Paste the content of <body> of your custom HTML here. Use only inline styling and no <style> elements."
                            multiline
                            minRows={7}
                            value={customHtmlDisplay}
                            onChange={event => {
                                const customtext = event.target.value;
                                setCursor(event.target.selectionStart);
                                onChange(infoRichText, customtext);
                            }}
                        />
                        {props.endAdornment && (
                            <Box position="absolute" bottom={16} right={16}>
                                {props.endAdornment}
                            </Box>
                        )}
                    </>
                </Stack>
            )} */}
            {!custom && (
                <HtmlEditor {...props} placeholder={placeholder || 'Add your content here.'} />
            )}
            {showConfirmation && (
                <Modal
                    title={'Warning'}
                    confirmText={'Change anyway'}
                    onConfirm={() => {
                        toggleCustom(showConfirmation === 'toCustom');
                    }}
                    onClose={() => {
                        setShowConfirmation('');
                    }}
                    actions={[
                        {
                            label: 'Confirm',
                            onClick: () => toggleCustom(showConfirmation === 'toCustom'),
                            variant: 'contained',
                        },
                        {
                            label: 'Cancel',
                            onClick: () => setShowConfirmation(''),
                            variant: 'contained',
                            color: 'secondary',
                        },
                    ]}
                >
                    <Typography sx={{ textAlign: 'left', marginBottom: '8px' }}>
                        {showConfirmation === 'toEditor'
                            ? 'Custom formatting that is not supported in the editor will be lost. Are you sure you want to change to the editor?'
                            : 'All content you have already added in the editor will be deleted. Are you sure you want to change to custom HTML?'}
                    </Typography>
                </Modal>
            )}
            <Modal
                title={`Custom HTML preview`}
                open={showPreview}
                onClose={handleClosePreview}
                size="sm"
                loading={false}
                actions={[{ label: 'Close', onClick: handleClosePreview, variant: 'text' }]}
                actionsPosition="left"
            >
                <Stack
                    sx={{
                        flexGrow: 1,
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '640px',
                    }}
                >
                    <Box
                        sx={{
                            flex: 1,
                            p: '20px',
                            backgroundColor: palette.UI_GREY_1,
                            width: '500px',
                            height: '640px',
                            overflowY: 'scroll',
                        }}
                    >
                        <div dangerouslySetInnerHTML={{ __html: customHtmlDisplay }} />
                    </Box>
                </Stack>
            </Modal>
        </Stack>
    );
};

export default CustomEnabledHtmlEditor;
