import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import DoneIcon from '@mui/icons-material/Done';
import { LightTooltip } from '../../virtualFeature/components/moderator/common/styles';
import { styled as muiStyled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
const FlexRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 64px;
    width: ${props => (props.marginright ? 'calc(50% - 8px)' : '50%')};
    margin-right: ${props => (props.marginright ? '8px' : '0')};
    margin-bottom: 8px;
    ${props =>
        props.isformodal &&
        `
        width: 100%;
    `}
`;

const LabelWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${props => (props.inverted ? '39px' : '32px')};
    height: 100%;
    border-radius: ${props => (props.inverted ? '0 6px 6px 0' : '6px 0 0 6px')};
    background-color: ${props => (props.inverted ? `${props.color}22` : '#f1f1f3')};
    color: rgba(0, 0, 0, 0.38);
    font-size: 17px;
    font-weight: 500;
    cursor: ${props =>
        props.inverted && props.notEmpty && !props.displayMode ? 'pointer' : 'default'};
    z-index: 10;

    :hover {
        ${props =>
            props.inverted &&
            props.notEmpty &&
            !props.displayMode &&
            css`
                background-color: ${props => props.color}22;
                color: ${props => props.color};
            `}
    }

    ${props =>
        (props.isFocused || props.notEmpty) &&
        css`
            background-color: ${props => props.color} ${props => props.inverted && '15'};
            color: ${props => (props.inverted && props.correctOption ? props.color : '#fff')};
        `}
`;

const TextInputWrapper = styled.input`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 16px;
    width: 100%;
    height: 100%;
    border-radius: ${props => (props.shouldDisplayCheckmark ? '0' : '0 6px 6px 0')};
    background-color: #f1f1f3;
    color: rgba(0, 0, 0, 0.38);
    font-size: 15px;
    font-weight: normal;
    margin-left: 2px;
    margin-right: ${props => props.shouldDisplayCheckmark && '2px'};
    margin-right: ${props => props.displayMode && '0px'};
    border: none !important;
    outline: none !important;
    z-index: 10;

    &:focus {
        border-bottom: 2px solid ${props => props.color} !important;
        border-top: 2px solid transparent !important;
        height: calc(100% - 4px);
    }

    ${props =>
        (props.isFocused || props.notEmpty) &&
        css`
            background-color: ${props => props.color}15;
            color: rgba(0, 0, 0, 0.87);
        `}
`;

const TextWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 16px;
    width: 100%;
    height: 100%;
    background-color: ${props => props.color}15;
    color: rgba(0, 0, 0, 0.87);
    font-size: 15px;
    font-weight: normal;
    margin-left: 2px;
    z-index: 10;
`;

const WideTooltip = muiStyled(LightTooltip)(() => ({
    tooltip: {
        maxWidth: '240px',
    },
}));

const MAX_ANSWER_CHARACTER_LIMIT = 150;

const VoteOption = ({
    label,
    option,
    setOption,
    disableThisOption,
    placeholder,
    color,
    setAsCorrectOption,
    displayMode,
    isForRecording,
    marginRight,
    isForModal,
}) => {
    const [isInputFocused, setIsInputFocused] = useState(false);
    const shouldDisplayCheckmark = option.text !== '' && !isInputFocused;
    const doneIconStyle = { color: option.correct ? color : '#fff' };

    const handleChange = event => {
        const { value } = event.target;

        if (value.length > MAX_ANSWER_CHARACTER_LIMIT) {
            const trimmedValue = value.slice(0, MAX_ANSWER_CHARACTER_LIMIT);
            setOption({
                ...option,
                text: trimmedValue,
            });
            return;
        }

        setOption({ ...option, text: value });
    };

    return (
        <FlexRow marginright={marginRight ? 1 : 0} isformodal={isForModal ? 1 : 0}>
            <LabelWrapper color={color} isFocused={isInputFocused} notEmpty={option.text !== ''}>
                {label}
            </LabelWrapper>
            {!isForRecording && (
                <TextInputWrapper
                    type="text"
                    value={option.text}
                    placeholder={placeholder}
                    disabled={disableThisOption}
                    onChange={handleChange}
                    color={color}
                    onFocus={() => setIsInputFocused(true)}
                    onBlur={() => setIsInputFocused(false)}
                    isFocused={isInputFocused}
                    notEmpty={option.text !== ''}
                    shouldDisplayCheckmark={shouldDisplayCheckmark && setAsCorrectOption}
                    displayMode={displayMode}
                />
            )}
            {isForRecording && (
                <TextWrapper placeholder={placeholder} color={color} displayMode={displayMode}>
                    {option.text}
                </TextWrapper>
            )}
            {shouldDisplayCheckmark && setAsCorrectOption && (
                <WideTooltip
                    title="Correct answer (not visible to attendees)"
                    disableHoverListener={!(displayMode && option.correct)}
                >
                    <Stack height="100%" justifyContent="center">
                        <LabelWrapper
                            color={color}
                            isFocused={isInputFocused}
                            notEmpty
                            inverted
                            correctOption={option.correct}
                            onClick={setAsCorrectOption}
                            displayMode={displayMode}
                        >
                            <DoneIcon style={doneIconStyle} />
                        </LabelWrapper>
                    </Stack>
                </WideTooltip>
            )}
        </FlexRow>
    );
};

export default VoteOption;
