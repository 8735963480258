import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useRouteMatch } from 'react-router-dom';

import useEvents from '../hooks/useEvents';
import EventInfoHeader from '../components/EventInfoHeader';
import { EventSessionsListWithTimezone } from '../components/EventSessionsList';
import { executeQuery } from '../../../services/api/graphQlRepository';
import isArray from 'lodash/isArray';
import EventInstitutionProposals from '../components/EventInstitutionProposals';
import Stack from '@mui/material/Stack';

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: fit-content;
`;

const EventInfo = () => {
    const { params } = useRouteMatch();
    const { eventName } = params;
    const [canVisit, setCanVisit] = useState('');
    const { getEventByUniqueName } = useEvents({ filter: '' });
    const event = getEventByUniqueName(eventName);

    useEffect(() => {
        const getEventAppSyncState = async () => {
            if (!event) {
                return;
            }
            let config = {};
            const societyDomain = window.location.hostname.split('.')[0];
            const appStateUniqueDomain = `${eventName}-${societyDomain}`.replace('-cui', '');
            const result = await executeQuery(
                'getAppStatesWithDomain',
                { domain: appStateUniqueDomain },
                true,
            );
            if (isArray(result)) {
                [config] = result;
            }
            if (event && event.webappEnabled && config && config.available) {
                if (event.available && !event.hidden) {
                    setCanVisit('open');
                } else if (!event.available && !event.hidden) {
                    setCanVisit('soon');
                }
            }
        };
        getEventAppSyncState();
    }, [event]);

    if (!(event && event.id)) {
        return null;
    }

    return (
        <Wrapper>
            <EventInfoHeader {...event} eventName={eventName} canVisit={canVisit} />
            <Stack direction="column" spacing={3} paddingY={5} width="100%">
                <EventInstitutionProposals event={event} />
                <EventSessionsListWithTimezone event={event} />
            </Stack>
        </Wrapper>
    );
};

export default EventInfo;
