import React, { useContext, useEffect, useState } from 'react';

import { withRouter } from 'react-router-dom';
import { List } from 'react-md';
import NavigationLink from '../../components/Navigation/NavigationLink';
import styled from 'styled-components';
import ObjectListItem from '../../components/ObjectListItem';
import ThemeContext from '../../components/Theme/ThemeContext';
import { getString } from '../../services/api/store';
import moment from 'moment';
import { getDateWithLocale, getFormatTime, getItemAsync } from '../../services/api/data';
import { TimezoneContext } from '../../scenes/Timezone/context';
import sortBy from 'lodash/sortBy';
import Auth from '../../services/api/auth';

const SectionTitle = styled.div`
    font-weight: bold;
    text-align: left;
    text-transform: capitalize;
    color: rgba(0, 0, 0, 0.87);
    font-size: 17px;
    line-height: 24px;
    margin: 20px 0 8px 16px;
    font-family: 'Cabin', sans-serif;
`;
const ListStyled = styled(List)`
    padding-bottom: 0px;
    padding-top: 0px;
    ${props =>
        props.flex &&
        `
    display: flex;
    flex-wrap: wrap;
    margin-left: 20px !important;
  `};
`;

const LinkStyled = styled(NavigationLink)`
    text-decoration: none !important;

    .md-fake-btn {
        ${props => props.selectedItem && 'background: #EBEDF0 !important'};
    }
`;
const AStyled = styled.a`
    text-decoration: none !important;
`;

const ListItem = ({ item, sectionType, displayIconsForLinks }) => (
    <span>
        <ObjectListItem
            item={item}
            type={sectionType}
            key={`${sectionType}_${item.id}`}
            isDetailPage={true}
        />
    </span>
);

const Section = props => {
    const sectionType = props.type;
    const timeArray = [];
    const isAuthenticated = Auth.isUserAuthenticated();
    const orderingArray = ['name'];
    let orderingString =
        (props.items &&
            props.items[0] &&
            Object.keys(props.items[0]).find(key => key.startsWith('order'))) ||
        'ordering';
    orderingArray.unshift(orderingString);
    if (props.items[0] && props.items[0].start) {
        orderingArray.unshift('start');
    }
    const sortedItems = sectionType !== 'mixed' ? sortBy(props.items, orderingArray) : props.items;
    const renderListItems = embed =>
        sortedItems
            ? sortedItems.map(function (it, index) {
                  const objectType = sectionType !== 'mixed' ? sectionType : it?.type;
                  const item = it;
                  let pathReplaceParams = null;
                  let navigationPath = '';
                  let navigationParams = {
                      type: 'detail',
                      objectClass: objectType,
                      objectId: item.id,
                  };

                  if (objectType === 'place') {
                      if (props.fromVirtualSession) {
                          return <ListItem key={index} sectionType={objectType} item={item} />;
                      }
                      if (item.floorplan && !embed) {
                          if (props.floorplanList) {
                              pathReplaceParams = { floorplan: item.floorplan, place: item.id };
                              navigationParams = null;
                          } else {
                              navigationPath = `/floorplan/${item.floorplan}/${item.id}`;
                              navigationParams = null;
                          }
                      } else {
                          navigationPath = '';
                          navigationParams = null;
                      }
                  } else if (objectType === 'person' && item.eurekaId && isAuthenticated) {
                      navigationParams = {
                          type: 'profile',
                          userId: item.eurekaId,
                          personLinkedId: item.id,
                      };
                  }

                  let start = moment.utc(item.start);
                  if (!start.isValid()) {
                      start = moment.unix(item.start);
                  }
                  const timeFormat = getFormatTime();
                  const timetoDisplay = moment(start).format(timeFormat);
                  const dateSettings = getString('datetime');
                  const datePattern =
                      dateSettings && dateSettings.dayMonthDateFormat
                          ? dateSettings.dayMonthDateFormat
                          : 'MMMM D';
                  const day = getDateWithLocale(start);
                  const dateToDisplay = day.format(datePattern);

                  item.dateTime = {
                      startTimeToDisplay: timetoDisplay,
                      startDateToDisplay: dateToDisplay,
                  };
                  timeArray.push({ dateTime: item.dateTime });

                  return (
                      <div key={index}>
                          {objectType !== 'link' && !item.onClick && (
                              <LinkStyled
                                  key={'no_link_' + index}
                                  path={navigationPath}
                                  pathReplaceParams={pathReplaceParams}
                                  params={navigationParams}
                                  selectedItem={props.location.pathname.includes(item.id)}
                              >
                                  <ListItem sectionType={objectType} item={item} />
                              </LinkStyled>
                          )}
                          {objectType !== 'link' && item.onClick && (
                              <div
                                  onClick={item.onClick}
                                  onKeyDown={e => {
                                      if (e.key === 'Enter') {
                                          item.onClick();
                                      }
                                  }}
                              >
                                  <ListItem sectionType={objectType} item={item} />
                              </div>
                          )}
                          {objectType === 'link' && !item.onClick && (
                              <AStyled
                                  key={'link_' + index}
                                  target="_blank"
                                  href={item.url ? item.url.replace('mailto://', 'mailto:') : '#'}
                              >
                                  <ListItem sectionType={objectType} item={item} />
                              </AStyled>
                          )}

                          {objectType === 'link' && item.onClick && (
                              <ListItem sectionType={objectType} item={item} />
                          )}
                      </div>
                  );
              })
            : [];

    return (
        <div>
            <ThemeContext.Consumer>
                {({ theme }) => (
                    <div>
                        {!!props.title && (
                            <SectionTitle secondary={theme.secondary}>{props.title}</SectionTitle>
                        )}
                        <ListStyled flex={props.type === 'classifier'} id="object-item-list">
                            {renderListItems(theme.embed)}
                        </ListStyled>
                    </div>
                )}
            </ThemeContext.Consumer>
        </div>
    );
};

export default withRouter(Section);
