import { create } from 'zustand';
import { useEffect } from 'react';
import { getItemAsync } from '../services/api/data';
import { getImageSourceAsync } from '../services/api/db';

export const useInstitutionsStore = create((set, get) => ({
    institutionSet: {},
    fetchInstitution: async id => {
        if (!id) {
            return;
        }
        const institutionExists = get().institutionSet[id];
        if (institutionExists) {
            return institutionExists;
        }

        const institution = await getItemAsync('institution', id);
        const institutionWithType = {
            ...institution,
            typeEntity: await getItemAsync('types', institution.type),
            bannerImageUrl: institution.bannerImage
                ? await getImageSourceAsync(institution.bannerImage)
                : null,
            cardImageUrl: institution.cardImage
                ? await getImageSourceAsync(institution.cardImage)
                : null,
            backgroundImageUrl: institution.backgroundImage
                ? await getImageSourceAsync(institution.backgroundImage)
                : null,
            imageUrl: institution.image ? await getImageSourceAsync(institution.image) : null,
        };
        set({ institutionSet: { ...get().institutionSet, [id]: institutionWithType } });
        return institutionWithType;
    },
}));

export const useInstitutionById = institutionId => {
    const fetchInstitution = useInstitutionsStore(state => state.fetchInstitution);

    useEffect(() => {
        fetchInstitution(institutionId);
    }, [institutionId]);

    return useInstitutionsStore(state => state.institutionSet[institutionId]);
};
