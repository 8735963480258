import React from 'react';
import styled from 'styled-components';
import * as palette from '../../../../components/General/Variables';
import { FontIcon } from 'react-md';

const Row = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: 8px;
`;

const Icon = styled(FontIcon)`
    color: ${props => props.color} !important;
    font-size: 18px !important;
`;

const IconWrapper = styled.div`
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:hover {
        background: rgba(0, 0, 0, 0.04);
        border-radius: 50%;
    }
`;

class StarsGroup extends React.PureComponent {
    renderStars() {
        const { primary } = this.props.theme;
        const { numberOfStars, iconTintColor, rate, onChange } = this.props;
        const iconTintColorParsed = iconTintColor || primary;
        const stars = [];

        for (let i = 0; i < numberOfStars; i += 1) {
            stars.push(
                <IconWrapper onClick={() => onChange(i + 1)}>
                    <Icon key={i} color={iconTintColorParsed}>
                        {rate > i ? palette.STAR : palette.STAR_OUTLINE}
                    </Icon>
                </IconWrapper>,
            );
        }

        return stars;
    }

    render() {
        return (
            <div>
                <Row>{this.renderStars()}</Row>
            </div>
        );
    }
}

export default StarsGroup;
