import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import get from 'lodash/get';
import PhonelinkIcon from '@mui/icons-material/Phonelink';
import PhonelinkOffIcon from '@mui/icons-material/PhonelinkOff';
import DeleteIcon from '@mui/icons-material/Delete';
import PlayArrow from '@mui/icons-material/PlayArrow';
import Stop from '@mui/icons-material/Stop';

import { LightTooltip, RedStyledChip, VideoSwitchOutlinedWrapper } from '../../common/styles';
import Dropdown from '../../../common/Dropdown';
import Loader from '../../../common/Loader';
import Stack from '@mui/material/Stack';
const Wrapper = styled('div', {
    shouldForwardProp: prop =>
        !['isActive', 'backgroundColor', 'isHovered', 'isdisabled'].includes(prop),
})(({ theme, isActive, backgroundColor, isHovered, isdisabled }) => ({
    alignItems: 'center',
    display: 'flex',
    height: isActive ? '88px' : '64px',
    padding: '12px 16px',
    position: 'relative',
    width: 'auto',
    borderRadius: '8px',
    margin: '0 1px',
    backgroundColor: backgroundColor,
    boxShadow: isHovered ? '0 2px 6px 0 rgba(0, 0, 0, 0.2)' : '',
    marginBottom: '8px',
    cursor: 'pointer',
    ...(isdisabled && {
        background: 'rgba(245, 245, 245, 0.5)',
        cursor: 'unset',
    }),
}));

const NameAndVideoInfo = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    flex: 2,
    width: 'calc(100% - 80px)',
});

const Flex = styled('div')({
    position: 'relative',
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
});

const ActionsWrapper = styled(Flex, {
    shouldForwardProp: prop => prop !== 'encoding',
})(({ encoding }) => ({
    zIndex: 3,
    justifyContent: encoding ? 'flex-end' : 'space-between',
}));

const Name = styled('div', {
    shouldForwardProp: prop => prop !== 'isdisabled',
})(({ isdisabled }) => ({
    color: 'rgba(0, 0, 0, 0.87)',
    fontFamily: 'Cabin, sans-serif',
    fontSize: '16px',
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.25,
    letterSpacing: 'normal',
    width: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    ...(isdisabled && {
        color: 'rgba(0, 0, 0, 0.38)',
    }),
}));

const VideoInfo = styled('div')({
    width: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: 'rgba(0, 0, 0, 0.54)',
    fontFamily: 'Roboto, sans-serif',
    fontSize: '14px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
});

const ActionWrapper = styled('div', {
    shouldForwardProp: prop => prop !== 'isdisabled',
})(({ isdisabled }) => ({
    position: 'relative',
    borderRadius: '50%',
    cursor: 'pointer',
    width: '40px',
    height: '40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'transparent !important',
    color: 'rgba(0, 0, 0, 0.54) !important',
    '&:hover': {
        color: 'rgba(0, 0, 0, 0.87) !important',
        backgroundColor: 'rgba(0, 0, 0, 0.04) !important',
    },
    '& > ul': {
        listStyleType: 'none',
        top: 0,
        padding: '16px',
        '& li': {
            '&:nth-of-type(2)': {
                margin: '6px 0',
            },
            '& div': {
                display: 'flex',
                alignItems: 'center',
                ...(isdisabled && {
                    opacity: 0.5,
                    cursor: 'not-allowed',
                }),
            },
        },
    },
}));

const PlayIcon = styled(PlayArrow)({
    cursor: 'pointer',
    background: 'transparent',
    borderRadius: '50%',
    padding: '4px',
    fontSize: '32px !important',
});

const StopIcon = styled(Stop)({
    cursor: 'pointer',
    background: 'transparent',
    borderRadius: '50%',
    padding: '4px',
    fontSize: '32px !important',
});

const PlayContainer = styled('div', {
    shouldForwardProp: prop => prop !== 'loading',
})(({ loading }) => ({
    width: loading ? '50px' : '40px',
    height: loading ? '50px' : '40px',
    borderRadius: '50%',
    color: 'rgba(0, 0, 0, 0.54)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&:hover': {
        color: 'rgba(0, 0, 0, 0.87)',
        backgroundColor: 'rgba(0, 0, 0, 0.04) !important',
    },
}));

const EmptySpace = styled('div')({
    width: '40px',
    height: '40px',
});

const EncodingWrapper = styled('div')({
    alignItems: 'center',
    display: 'flex',
    fontFamily: 'Roboto, sans-serif',
    fontSize: '13px',
    lineHeight: 1.54,
    textAlign: 'right',
    color: 'rgba(0, 0, 0, 0.38)',
    position: 'relative',
});

const videoTypeMap = {
    preRecordedPresentationUrl: 'Pre-recording',
    uploadedVideo: 'Uploaded video',
    rtmp: 'RTMP Stream',
};

const VideoLoader = styled(Loader)({
    margin: 0,
    boxShadow: 'unset !important',
});

const NarrowedLightTooltip = styled(LightTooltip)({
    '& .MuiTooltip-tooltip': {
        maxWidth: '80px',
    },
});

const RedChip = styled(RedStyledChip, {
    shouldForwardProp: prop => prop !== 'isPlaying',
})(({ isPlaying }) => ({
    width: isPlaying ? '135px' : '88px',
    margin: 0,
}));

const CompressingLoader = styled(Loader)({
    boxShadow: 'unset !important',
    width: '25px',
    height: '25px',
    margin: '0 10px 0 0',
    background: 'transparent',
});

const IconWrapper = styled('div', {
    shouldForwardProp: prop => prop !== 'disabled',
})(({ disabled }) => ({
    width: '40px',
    height: '40px',
    borderRadius: '50px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    transition: 'all 0.1s linear',
    backgroundColor: 'transparent',
    zIndex: 1,
    '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.04)',
        color: 'rgba(0, 0, 0, 0.87)',
    },
    ...(disabled && {
        cursor: 'not-allowed',
    }),
}));

const VideoWrapper = ({
    virtualEventUser,
    video,
    makeVideoActive,
    isDisabled,
    playing,
    playMedia,
    stopMedia,
    loading,
    onMoveUp,
    onMoveDown,
    onDelete,
    onEdit,
    isActiveVideo,
    ...props
}) => {
    const { hasVideoActive, User: user } = virtualEventUser || {};
    const isActive = hasVideoActive || isActiveVideo;
    const isVideo = get(video, 'name');
    const isRTMP = get(video, 'isRTMP');

    const videoSwitchWrapperColor = 'rgba(0, 0, 0, 0.54)';
    const videoSwitchWrapperColorHover = 'rgba(0, 0, 0, 0.04)';
    const switchIconColorHover = 'rgba(0, 0, 0, 0.87)';

    const [tooltipOpen, setTooltipOpen] = useState(false);
    const [displayActions, setDisplayActions] = useState(false);
    const [encodingStatus, setEncodingStatus] = useState('complete');

    useEffect(() => {
        if (!isVideo && !user && !isRTMP) {
            return null;
        }
        if (get(video, 'name')) {
            setEncodingStatus(get(video, 'encoding'));
        } else {
            setEncodingStatus('complete');
        }
    }, [video]);

    if (!isVideo && !user && !isRTMP) {
        return null;
    }

    const getVideoType = () => {
        if (isRTMP) {
            return 'rtmp';
        }

        return isVideo ? 'uploadedVideo' : 'preRecordedPresentationUrl';
    };

    const name = get(video, 'name') || `${user.firstName} ${user.lastName}`;
    const videoType = getVideoType(video);

    const isEncodingPending = encodingStatus === 'pending';
    const isEncodingRunning = encodingStatus === 'running';
    const isEncoding = isEncodingPending || isEncodingRunning;
    const shouldDisable = isDisabled;

    if (!video && !get(virtualEventUser, 'preRecordedPresentationUrl')) {
        return null;
    }

    const duration = isVideo ? video.duration : get(virtualEventUser, 'recordingInfo.duration', 0);

    const onSwitchClick = () => {
        if (!shouldDisable) {
            makeVideoActive();
        }

        setTooltipOpen(false);
    };

    const deleteVideo = e => {
        e.stopPropagation();
        onDelete();
    };

    const onEditVideo = e => {
        e.stopPropagation();
        onEdit();
    };

    const moveUp = e => {
        e.stopPropagation();
        onMoveUp();
    };

    const moveDown = e => {
        e.stopPropagation();
        onMoveDown();
    };

    return (
        <Wrapper
            data-qa={props['data-qa']}
            isdisabled={shouldDisable ? 1 : 0}
            onMouseEnter={() => setDisplayActions(true)}
            onMouseLeave={() => setDisplayActions(false)}
            backgroundColor={'#f1f1f3'}
            isHovered={displayActions}
            isActive={isActive}
        >
            <Flex>
                <NameAndVideoInfo>
                    {isActive && !playing && <RedChip>ACTIVE VIDEO</RedChip>}
                    {isActive && playing && <RedChip isPlaying>ACTIVE VIDEO, PLAYING</RedChip>}
                    <Name isdisabled={shouldDisable ? 1 : 0}>{name}</Name>

                    <VideoInfo>
                        {!isEncoding && (
                            <span>{`${videoTypeMap[videoType]} ${
                                videoType === 'rtmp' ? '' : `(${duration})`
                            }`}</span>
                        )}
                        {isEncodingRunning && <span>Processing...</span>}
                        {isEncodingPending && <span>Waiting...</span>}
                    </VideoInfo>
                </NameAndVideoInfo>

                <ActionsWrapper encoding={isEncoding}>
                    {isActive && displayActions && !isRTMP && (
                        <NarrowedLightTooltip
                            title={playing ? 'Stop this video' : 'Play this video'}
                        >
                            <Stack>
                                <PlayContainer loading={loading ? 1 : 0}>
                                    {loading && <VideoLoader />}

                                    {!playing && !loading && (
                                        <PlayIcon onClick={e => !shouldDisable && playMedia(e)} />
                                    )}

                                    {playing && !loading && (
                                        <StopIcon onClick={e => !shouldDisable && stopMedia(e)} />
                                    )}
                                </PlayContainer>
                            </Stack>
                        </NarrowedLightTooltip>
                    )}
                    {!isActive && !displayActions && (
                        <React.Fragment>
                            {isEncoding && (
                                <EncodingWrapper>
                                    <CompressingLoader
                                        extraCircleStyle={{
                                            color: 'gray',
                                            marginLeft: '5px',
                                            height: '15px',
                                            width: '15px',
                                        }}
                                    />
                                </EncodingWrapper>
                            )}
                            {!isEncoding && <EmptySpace />}
                        </React.Fragment>
                    )}
                    {displayActions && isEncoding && (
                        <IconWrapper onClick={e => !shouldDisable && deleteVideo(e)}>
                            <DeleteIcon />
                        </IconWrapper>
                    )}
                    {displayActions && !isEncoding && (
                        <NarrowedLightTooltip title={'Options'}>
                            <Stack>
                                <ActionWrapper isdisabled={shouldDisable ? 1 : 0}>
                                    <Dropdown
                                        customPosition={'top'}
                                        items={[
                                            ...(isRTMP
                                                ? [
                                                      {
                                                          text: 'Edit',
                                                          icon: 'edit',
                                                          onClick: e =>
                                                              !shouldDisable && onEditVideo(e),
                                                      },
                                                  ]
                                                : []),
                                            {
                                                text: 'Move up',
                                                icon: 'arrow_upward',
                                                onClick: e => !shouldDisable && moveUp(e),
                                            },
                                            {
                                                text: 'Move down',
                                                icon: 'arrow_downward',
                                                onClick: e => !shouldDisable && moveDown(e),
                                            },
                                            {
                                                text: 'Delete',
                                                icon: 'delete',
                                                onClick: e => !shouldDisable && deleteVideo(e),
                                            },
                                        ]}
                                    />
                                </ActionWrapper>
                            </Stack>
                        </NarrowedLightTooltip>
                    )}
                    {displayActions && !isEncoding && (
                        <NarrowedLightTooltip
                            open={tooltipOpen}
                            title={
                                isActive
                                    ? 'Unselect as the active video'
                                    : 'Select as the active video'
                            }
                        >
                            <Stack>
                                <VideoSwitchOutlinedWrapper
                                    backgroundColor={videoSwitchWrapperColor}
                                    backgroundColorHover={videoSwitchWrapperColorHover}
                                    colorHover={switchIconColorHover}
                                    onClick={onSwitchClick}
                                    onMouseEnter={() => {
                                        setTooltipOpen(true);
                                    }}
                                    onMouseLeave={() => {
                                        setTooltipOpen(false);
                                    }}
                                    active={isActive}
                                >
                                    {!isActive ? <PhonelinkIcon /> : <PhonelinkOffIcon />}
                                </VideoSwitchOutlinedWrapper>
                            </Stack>
                        </NarrowedLightTooltip>
                    )}
                </ActionsWrapper>
            </Flex>
        </Wrapper>
    );
};

export default VideoWrapper;
