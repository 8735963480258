import React, { useRef, useState, useEffect } from 'react';
import { getImageSource, getItem } from '../services/floorplan';
import Map from './map';
import { usePlacesByFloorplan } from '../../../stores/PlacesStore';

const Floorplan = props => {
    const places = usePlacesByFloorplan(props.match.params.floorplan);
    const [state, setState] = useState({
        loading: true,
        reset: false,
        imageRatio: undefined,
        image: null,
        mapWidth: null,
        floorplanId: null,
        selectedPlace: null,
    });

    const mapInfoLoad = useRef();

    const processData = data => {
        if (data.imageRatio && state.imageRatio !== data.imageRatio) {
            setState(prev => ({ ...prev, imageRatio: data.imageRatio }));
        }
    };

    const resize = () => {};

    const load = floorplanId => {
        getItem('floorplans', floorplanId, (err, floorplan) => {
            if (floorplan && floorplan.image) {
                getImageSource(floorplan.imageUrl || floorplan.image, (err, result) => {
                    const node = mapInfoLoad.current;
                    if (!node) {
                        return;
                    }
                    const specs = node.getBoundingClientRect();
                    setState(prev => ({
                        ...prev,
                        loading: false,
                        image: result,
                        mapWidth: specs.width,
                    }));
                });
            }
        });
    };

    // getDerivedStateFromProps + componentDidUpdate equivalent
    useEffect(() => {
        if (props.match.params.floorplan !== state.floorplanId) {
            load(props.match.params.floorplan, props.match.params.place);
            props.onActiveFloorplan(props.match.params.floorplan);
            setState(prev => ({
                ...prev,
                loading: true,
                floorplanId: props.match.params.floorplan,
                selectedPlace: props.match.params.place,
            }));
        } else if (props.match.params.place !== state.selectedPlace) {
            setState(prev => ({
                ...prev,
                selectedPlace: props.match.params.place,
            }));
        }
    }, [props.match.params.floorplan, props.match.params.place]);

    const height = window.innerHeight - 120 + 'px';
    const maxWidth = window.innerWidth + 'px';
    const width = '100%';
    const containerStyle = {
        width: width,
        height: height,
        maxWidth: maxWidth,
        paddingTop: '24px',
        paddingLeft: '8px',
        paddingRight: '8px',
    };

    return (
        <div>
            <div ref={mapInfoLoad}>
                {state.image && (
                    <div style={containerStyle}>
                        <Map
                            key={state.floorplanId}
                            id={state.floorplanId}
                            places={places}
                            selectedPlace={state.selectedPlace}
                            resize={resize}
                            process={processData}
                            initialScale={'auto'}
                            minScale={'auto'}
                            maxScale={4}
                            searchButtonAction={props.searchButtonAction}
                        >
                            <img alt="floorplan" src={state.image} />
                        </Map>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Floorplan;
