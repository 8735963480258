import { eurekaRequest } from '../../../services/api/eureka';

export const getRegisteredSessions = eventId => {
    return new Promise((resolve, reject) => {
        eurekaRequest(
            'get',
            `/api/entities/sessionRegistration/me/${eventId}`,
            null,
            true,
            (err, response) => {
                if (err) {
                    reject(err);
                } else {
                    resolve(response);
                }
            },
        );
    });
};

export const updateSessionRegistration = (eventId, sessionId, data) => {
    return new Promise((resolve, reject) => {
        eurekaRequest(
            'post',
            `/api/entities/sessionRegistration/me/${eventId}/${sessionId}`,
            data,
            true,
            (err, response) => {
                if (err) {
                    reject(err);
                } else {
                    resolve(response);
                }
            },
        );
    });
};

export const getRegistrationSessionCapacity = (eventId, sessionId) => {
    return new Promise((resolve, reject) => {
        eurekaRequest(
            'get',
            `/api/entities/sessionRegistration/count/${eventId}/${sessionId}`,
            null,
            true,
            (err, response) => {
                if (err) {
                    reject(err);
                } else {
                    resolve(response);
                }
            },
        );
    });
};

export const syncExternalRegistrations = (eventId, externalSyncBrand) => {
    return new Promise((resolve, reject) => {
        eurekaRequest(
            'get',
            `/api/entities/sessionRegistration/external/${eventId}/${externalSyncBrand}`,
            null,
            true,
            (err, response) => {
                if (err) {
                    reject(err);
                } else {
                    resolve(response);
                }
            },
        );
    });
};
