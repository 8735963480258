import React, { useState, useEffect } from 'react';
import { useFormikContext } from 'formik';
import Box from '@mui/material/Box';
import FloqTextInput from '../../../components/FloqTextInput';
import CustomEnabledHtmlEditor from '../../../components/HtmlEditor/CustomEnabledHtmlEditor';
import {
    createInfoRichTextInitialValue,
    deserializeFromHtml,
} from '../../../components/HtmlEditor/HtmlEditor';
import debounce from 'lodash/debounce';

const InstitutionContentInfoTab = ({ institution, institutionProposalData, reset }) => {
    const formik = useFormikContext();
    const isBooth = institution.typeEntity?.detailPageLayout === 'booth';
    const info = institutionProposalData?.info || institution.info;

    const [infoText, setInfoText] = useState(createInfoRichTextInitialValue(''));
    const [localInfoText, setLocalInfoText] = useState('');
    const [canReset, setCanReset] = useState(false);
    const handleFormikInfoTextChange = debounce(value => {
        formik.setFieldValue('info', value);
    }, 400);

    useEffect(() => {
        if (info && info.length) {
            setLocalInfoText(info);
            setCanReset(true);
            const infoDocument = new window.DOMParser().parseFromString(info, 'text/html');
            const isHtml = Array.from(infoDocument.body.childNodes).some(
                node => node.nodeType === 1,
            );
            const text = isHtml
                ? deserializeFromHtml(infoDocument.body)
                : createInfoRichTextInitialValue(info);

            setInfoText(text);
        }
    }, [info]);

    useEffect(() => {
        if (reset) {
            setCanReset(true);
        }
    }, [reset]);

    const videoUrl = formik.values.videoUrl;
    const videoUrlError = formik.errors.videoUrl;
    const custom = formik.values.custom;

    return (
        <Box padding={3}>
            {isBooth && (
                <Box paddingBottom={'14px'}>
                    <FloqTextInput
                        label="YouTube or Vimeo video URL (optional)"
                        value={videoUrl || ''}
                        error={videoUrlError}
                        onChange={value => {
                            formik.setFieldValue('videoUrl', value);
                            formik.setErrors({
                                ...formik.errors,
                                videoUrl: '',
                            });
                        }}
                    />
                </Box>
            )}
            <CustomEnabledHtmlEditor
                withHeadings
                withImages
                document={infoText}
                custom={custom}
                reset={canReset}
                onChange={(value, html, custom) => {
                    setLocalInfoText(html);
                    // if (onTagChange) {
                    //     onTagChange(html, !canReset, undefined, custom);
                    // }
                    // if (onPersonChange) {
                    //     onPersonChange(html, !canReset, undefined, custom);
                    // }
                    // if (onInstitutionChange) {
                    //     onInstitutionChange(html, !canReset, undefined, custom);
                    // }
                    handleFormikInfoTextChange(html);
                    if (custom === false) {
                        setInfoText(value);
                        setCanReset(true);
                    } else {
                        setCanReset(false);
                    }
                }}
                placeholder={'Company info (optional)'}
                customHtml={localInfoText}
            />
        </Box>
    );
};

export default InstitutionContentInfoTab;
